<template>
  <div id="cash-flow-tab">
    <div class="ds-container">
      <div class="ds-header">
        <div class="ds-title">
          Fluxo de caixa
          <span id="help-icon" class="icon-box"><v-help-circle class="icon stroke" /></span>
          <b-tooltip target="help-icon" placement="bottom">
            Verifique o valor total de entradas, saídas, movimentações, saldo total dos dias e os detalhes de cada movimentação.
          </b-tooltip>
        </div>
      </div>
      <CashflowResume :bankAccounts="bankAccounts" />
      <MovementsResume :bankAccounts="bankAccounts" @refresh="getBankAccounts()"/>
      <div class="loading-wrapper">
        <Loading :class="{ reload: true, loading: loading }" @click="() => !loading && getCashFlow()"/>
        <b-button
          class="wh-button"
          variant="outline-primary"
          outline
          :disabled="loading"
          @click="getXlsx"
        >
          Download xlsx
        </b-button>
      </div>
      <div class="row">
        <div class="col-6">
          <b-form-group class="form-group">
              <label>Conta</label>
              <!-- <Select v-model="bankAccountList" :options="bankAccounts"/> -->
              <CheckboxSelect
                trackBy="id"
                optionLabel="name"
                label="Todas as contas"
                :value="bankAccountList"
                :options="bankAccounts.filter(el => el.id)"
                @onChange="changeSelectedBankAccounts"
                placeholder="Pesquisar"
              />
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group class="form-group">
              <label>Período</label>
              <Periods v-model="period" @select="value => (period = value)" />
          </b-form-group>
        </div>
      </div>
      <CashflowTable 
        :cashFlow="cashFlow" 
        @onEdit="showDetails"
      />
    </div>

    <CashFlowDetailsModal 
      :bankAccountList="bankAccountList"
      :date="date"
      @hidden="onHiddenDetails"
    />
  </div>
</template>

<script>
import api from '../api'
import { saveAs } from 'file-saver';
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  components: {
    Loading: () => import('@/assets/icons/loading.svg'),
    'v-help-circle': () => import('@/assets/icons/lightblue-help-circle.svg'),
    CheckboxSelect: () => import('@/components/CheckboxSelect'),
    Periods: () => import('@/components/General/Periods'),
    MovementsResume: () => import('@/modules/financial/components/MovementsResume'),
    CashflowResume: () => import('@/modules/financial/components/CashflowResume'),
    CashflowTable: () => import('@/modules/financial/components/CashflowTable'),
    CashFlowDetailsModal: () => import('@/modules/financial/modals/CashFlowDetailsModal'),
  },
  mounted() {
    this.getCashFlow();
    this.getBankAccounts();
  },
  data() {
    const date = new Date()
    return {
      loading: false,
      clinic: getCurrentClinic(),
      cashFlow: {},
      chevronState: false,
      currentMonth: new Date(),
      bankAccountList: [],
      bankAccounts: [],
      period: {
          start: new Date(date.getFullYear(), date.getMonth(), 1),
          end: new Date(date.getFullYear(), date.getMonth() + 1, 0),
      },
      date: null,
      status_payments: [
        { label: 'Vencido', value: 'Vencido' },
        { label: 'Pago', value: 'Pago' },
        { label: 'Pendente', value: 'Pendente' },
        { label: 'Parcelado', value: 'Parcelado' },
        { label: 'Vencimento próximo', value: 'Vencimento próximo' }
      ],

      totalIn: 0,
      totalOut: 0,
      amount: 0,
      movementsTotal: 0,
      billsToReceive: [],
      billsToPay: [],
      dataMovements: []
    }
  },
  methods: {
    chevronRotate() {
      if(this.chevronState === false) {
        const chevron = document.getElementById('chevron')
        chevron.classList.toggle('chevron-active')
        this.chevronState = true
      }
    },
    resetChevronState() {
      if(this.chevronState === true) {
        const chevron = document.getElementById('chevron')
        chevron.classList.toggle('chevron-active')
        this.chevronState = false
      }
    },
    getBankAccounts() {
      api.getBankAccountBillToReceive(this.clinic.id)
      .then(res => {
        this.bankAccounts = res.data.map(el => ({ ...el, label: `${el.name}` }));
        this.bankAccounts.unshift(({ label: 'Todas as contas', value: null, id: null }))
      });

    },
    getCashFlow() {
      this.loading = true;
      api.getCashFlow(this.clinic.id, this.bankAccountList.map(x => x.id), this.period.start, this.period.end)
        .then(({ data }) => {
          this.cashFlow = Object.keys(data.data).length ? data.data : {}
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => (this.loading = false))
    },
    changeSelectedBankAccounts(bankAccountList) {
      this.bankAccountList = bankAccountList
    },
    showDetails(date) {
      this.date = date
      this.$bvModal.show('cash-flow-details-modal')
    },
    onHiddenDetails() {
      this.date = null
      this.getCashFlow()
    },
    getXlsx() {
      this.loading = true
      api.getCashFlowExport(this.clinic.id, this.bankAccountList.map(x => x.id), this.period.start, this.period.end)
      .then(res => {
        saveAs(
          new Blob([res.data], {
            type: 'application/vnd.ms-excel',
          }),
          'fluxo_de_caixa.xlsx'
        );
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => (this.loading = false));
    }
  },
  watch: {
    bankAccountList() {
      this.getCashFlow()
    },
    period: {
      handler: function () {
        this.getCashFlow()
      },
      deep: true
    },
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/vx-datepicker.scss';

#cash-flow-tab {
  .loading-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }
  .ds-container {
    padding: 30px 10px;

    .ds-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0 30px 0;


      .ds-title {
        display: inline-flex;
        align-items: center;
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
  #help-icon {
    height: 28px;
    margin-left: 5px;
  }
}
</style>

